import {NestedTreeControl} from "@angular/cdk/tree";
import {BscwTreeNode} from "./bscw-tree-node";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {SelectionModel} from "@angular/cdk/collections";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BscwTreeDialogData, BscwTreeDialogMode} from "./bscw-tree-dialog-data";
import {EdcApiService} from "../../../core/services/api/edc-api.service";
import {NotificationService} from "../../../core/services/notification.service";
import {Component, Inject} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'bscw-tree',
  templateUrl: './bscw-tree.component.html',
  styleUrls: ['./bscw-tree.component.scss']
})


export class BscwTreeComponent {
  private readonly OID = 597; // IMPORTANT OID for the root
  private readonly credentials: string;
  protected readonly mode: BscwTreeDialogMode;

  protected treeControl = new NestedTreeControl<BscwTreeNode>(node => node.children);
  protected selection: SelectionModel<BscwTreeNode>;
  protected dataSource = new MatTreeNestedDataSource<BscwTreeNode>();
  protected treeData: BscwTreeNode[] = [];

  protected fileName = '';

  hasChild = (_: number, node: BscwTreeNode) => node.expandable;

  constructor(
    public dialogRef: MatDialogRef<BscwTreeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BscwTreeDialogData,
    private edcApiService: EdcApiService,
    private notificationService: NotificationService,
    translateService: TranslateService,
  ) {
    this.mode = data.mode;
    this.selection = new SelectionModel<BscwTreeNode>(false);
    this.treeData.push({
      name: translateService.instant('bscw_browser.root'),
      oid: this.OID,
      expandable: true,
      isLoading: false,
      children: undefined
    });
    this.credentials = btoa(data.username + ":" + data.password);
    if(this.mode == BscwTreeDialogMode.DATASOURCE) {
      this.loadChildren(this.treeData[0]); // initial fetch
      this.treeControl.expand(this.treeData[0]);
    } else if (this.mode == BscwTreeDialogMode.DATASINK) {
      this.loadAll(this.treeData[0])
        .then(() => {
          this.treeControl.dataNodes = this.treeData.slice(0, 1);
          this.treeControl.expandAll();
        }); // initial fetch
    }

  }

  loadChildren(node: BscwTreeNode) {
    if(node.expandable && !node.isLoading && !node.children) {
      node.isLoading = true;
      this.edcApiService.edcClient.uiApi.getBscwMetadataForOID({oid: node.oid, authorization: this.credentials}).then((data: any) => {
        node.children = this.mapBscwMetadataToTreeNodes(data);
        this.reloadTree(); // trigger change detection
      }).catch(() => {
        this.notificationService.showError('Wrong username or password or other error while fetching from BSCW!');
      }).finally(() => node.isLoading = false);
    }
  }

  async loadAll(node: BscwTreeNode) {
    node.isLoading = true;
    this.loadAllRecursive(node)
      .then(() => {
        this.reloadTree(); // trigger change detection
      }).catch(() => {
        this.notificationService.showError('Wrong username or password or other error while fetching from BSCW!');
      }).finally(() => node.isLoading = false);
  }

  async loadAllRecursive(node: BscwTreeNode) {
    const directoryChildren = await this.edcApiService.edcClient.uiApi.getBscwMetadataForOID({
      oid: node.oid,
      authorization: this.credentials
    }).then((data: any) => this.mapBscwMetadataToTreeNodes(data).filter((item: any) => item.expandable)); // get only directories
    node.expandable = directoryChildren.length > 0;
    node.children = directoryChildren;
    node.children.forEach((child: BscwTreeNode) => this.loadAllRecursive(child));
  }

  mapBscwMetadataToTreeNodes(data: any): BscwTreeNode[] {
    return (data as any[]).map((item: any) => {
      return {
        name: item['bscw:name'],
        oid: item['bscw:oid'],
        expandable: item['bscw:class'] == 'bscw.core.cl_folder.Folder',
        isLoading: false,
      };
    });
  }

  reloadTree(): void {
    const temp_data = this.treeData;
    this.dataSource.data = [];
    this.dataSource.data = temp_data; // reassign to trigger change detection
  }

  getSelectedUrl(): string | null {
    return this.selection.hasValue() ? this.selection.selected[0].oid.toString() : null;
  }

  isValid(): boolean {
    let valid = this.getSelectedUrl() != null;
    if(this.mode == BscwTreeDialogMode.DATASINK) {
      valid = valid && this.fileName != '';
    }
    return valid;
  }

  onSelectClick(): void {
    let url = this.getSelectedUrl();
    if(this.mode == BscwTreeDialogMode.DATASINK) {
      url += '/' + this.fileName;
    }
    this.dialogRef.close(url);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  protected readonly BscwTreeDialogMode = BscwTreeDialogMode;
}

import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {EdcApiService} from './api/edc-api.service';

/**
 * Wrapped AssetService with AssetPropertyMapper
 */
@Injectable({
  providedIn: 'root',
})
export class CatalogContractService {
  constructor(
    private edcApiService: EdcApiService,
  ) {}

  private assetIdsWithContract: string[] = [];

  fetchAssetIdsWithContract(): string[] {
      return this.assetIdsWithContract;
  }

  async reload(onlyFirstTime: boolean = false) {
    if(onlyFirstTime && this.assetIdsWithContract.length > 0) {
      return;
    }
    this.assetIdsWithContract = await firstValueFrom(this.edcApiService.getCatalogPageAssetIdsWithContract());
  }
}

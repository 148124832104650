import {Provider} from '@angular/core';
import {APP_CONFIG, AppConfig, LIVING_APP_CONFIG} from './app-config';
import {AppConfigBuilder} from './app-config.builder';
import {AppConfigFetcher} from './app-config.fetcher';
import {AppConfigMerger} from './app-config.merger';
import {BehaviorSubject} from "rxjs";

let appConfig: AppConfig | null = null;
let livingAppConfig: BehaviorSubject<AppConfig> | null = null;

export async function loadAppConfig(accessToken: string | null = null): Promise<AppConfig> {
  const merger = new AppConfigMerger();
  const builder = new AppConfigBuilder();
  const fetcher = new AppConfigFetcher(merger);
  return fetcher
    .fetchEffectiveConfig('/assets/config/app-configuration.json', null)
    .then((json) => builder.buildAppConfig(json))
    .then(async (config) => {
      if (accessToken) {
        await fetcher.fetchApiKey(config, accessToken).then((token) => config.managementApiKey = token)
      }
      return config;
    })
    .then((config) => { livingAppConfig?.next(config); return config; })
    .then((config) => (appConfig = config));
}

export async function loadLivingAppConfig(): Promise<BehaviorSubject<AppConfig>> {
  return loadAppConfig()
    .then((config) => (livingAppConfig = new BehaviorSubject<AppConfig>(config)));
}

export const provideAppConfig = (): Provider => ({
  provide: APP_CONFIG,
  useFactory: () => appConfig,
});

export const provideLivingAppConfig = (): Provider => ({
    provide: LIVING_APP_CONFIG,
    useFactory: () => livingAppConfig
});

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LIVING_APP_CONFIG, AppConfig} from "../../config/app-config";


@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  private apiKey: string|null = null;

  constructor(@Inject(LIVING_APP_CONFIG) livingAppConfig: BehaviorSubject<AppConfig|null>) {
    livingAppConfig.subscribe((config) => {
      this.apiKey = (config ? config.managementApiKey : null);
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    //Check to bypass interceptor for requests not directed to the Management API
    if (req.headers.has('X-Bypass-Interceptor')) {
      // Clone the request without the special header
      const newReq = req.clone({
        headers: req.headers.delete('X-Bypass-Interceptor')
      });
      // Pass the modified request to the next handler without intercepting
      return next.handle(newReq);
    }

    if (this.apiKey) {
      req = req.clone({
        setHeaders: {'X-Api-Key': this.apiKey},
      });
    }
    return next.handle(req);
  }
}

import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

/**
 * Validates whether control's value is matching the BSCW URL scheme
 * @param control control
 */
export const bscwValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control.value;
  if (value) {
    if(typeof(value) !== 'string') {
      return {bscwTypeInvalid: true};
    } else if(!value.startsWith('https://myotis.fit.fraunhofer.de/kultur/sec/bscw.cgi/')) {
      return {bscwUrlInvalid: true};
    }
  }
  return null;
}

<h1 mat-dialog-title>{{ ((mode === BscwTreeDialogMode.DATASOURCE) ? 'bscw_browser.title_source' : 'bscw_browser.title_sink') | translate }}</h1>
<mat-dialog-content>
  <div *ngIf="mode === BscwTreeDialogMode.DATASINK" class="form-section-title">{{ 'bscw_browser.sink_directory' | translate }}</div>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">

    <!-- tree layout for DATA SOURCE -->
    <div *ngIf="mode === BscwTreeDialogMode.DATASOURCE">
      <!-- Expandable node -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle (click)="loadChildren(node)">
            <mat-icon>
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
            {{node.name}}
            <mat-spinner *ngIf="node != treeData[0] && node.isLoading" mode="indeterminate" color="primary" diameter="12"></mat-spinner>
          </button>
        </div>
        <div [class.tree-invisible-node]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>

      <!-- Leaf node -->
      <mat-nested-tree-node *matTreeNodeDef="let node">
        <div class="mat-tree-node">
          <mat-checkbox
            [checked]="selection.isSelected(node)"
            (change)="selection.toggle(node)">
            {{node.name}}
          </mat-checkbox>
        </div>
      </mat-nested-tree-node>
    </div>

    <!-- tree layout for DATA SINK -->
    <div *ngIf="mode === BscwTreeDialogMode.DATASINK">
      <!-- Expandable node -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <mat-checkbox
            [checked]="selection.isSelected(node)"
            (change)="selection.toggle(node)">
            {{node.name}}
          </mat-checkbox>
        </div>
        <div [class.tree-invisible-node]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>

      <!-- Leaf node -->
      <mat-nested-tree-node *matTreeNodeDef="let node">
        <div class="mat-tree-node">
          <mat-checkbox
            [checked]="selection.isSelected(node)"
            (change)="selection.toggle(node)">
            {{node.name}}
          </mat-checkbox>
        </div>
      </mat-nested-tree-node>
    </div>
  </mat-tree>
  <div class="main-spinner" *ngIf="treeData[0].isLoading">
    <mat-spinner color="primary" diameter="50"></mat-spinner>
  </div>
  <ng-container *ngIf="mode == BscwTreeDialogMode.DATASINK">
    <div class="form-section-title">{{ 'bscw_browser.sink_filename' | translate }}</div>
    <div class="flex flex-row space-x-[10px]">
      <mat-form-field class="grow">
        <mat-label>{{ 'bscw_browser.sink_filename' | translate }}</mat-label>
        <input matInput required [(ngModel)]="fileName" [placeholder]="'example.txt'" />
      </mat-form-field>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="flex flex-row justify-end">
  <button mat-button (click)="onCancelClick()">{{ 'general.cancel' | translate }}</button>
  <button mat-button [disabled]="!isValid()" (click)="onSelectClick()">{{ 'general.select' | translate }}</button>
</mat-dialog-actions>
